import { useEffect } from 'react';
import * as React from 'react';
import { ButtonEmpty } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { SubmissionsDashboardProvider } from '@app/cx/Dashboard/Submissions/Context';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { OrgLevelType } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { SubmissionFlyout } from '../Alert';

export const OnboardAccountButton: React.FC = () => {
  try {
    const { account } = useAuth();
    const tracker = useTracker();

    const { selectedOrganization } = useUserSession();
    const { showFlyout } = useFlyout();

    const { data } = useOrgInfoQuery({
      variables: {
        input: {
          orgName: selectedOrganization?.name,
        },
      },
    });
    const hasCreateOrganizationPermission = data?.orgInfo?.permissionsV2?.some(
      (permission) => permission.resource === 'Organization' && permission.action === 'Create',
    );

    const permissions = getOrgPermissions(account, selectedOrganization?.id);
    const isReviewer = !account?.managedOrgs?.some((o) => o.editProperties || o.editSubmissions);
    const canManageSubmissions =
      permissions?.includes('canManageSubmissions') || (account.organizationName && !isReviewer);

    const isEnterprise = selectedOrganization?.orgLevel === OrgLevelType.Enterprise;

    const enterpriseOrgName = isEnterprise
      ? selectedOrganization.name
      : data?.orgInfo?.orgInfo?.enterpriseOrg?.name;

    const shouldShowDialog = hasCreateOrganizationPermission && !!enterpriseOrgName;

    useEffect(() => {
      if ((window as any)?.aptrinsic) {
        (window as any)?.aptrinsic('set', 'globalContext', {
          showOnboardingDialog: shouldShowDialog,
        });

        if (shouldShowDialog) {
          tracker.track('In-App Alert V2: Viewed');
        }
      }
    }, [data]);

    if (!shouldShowDialog) {
      return null;
    }

    return (
      <SubmissionsDashboardProvider>
        {canManageSubmissions && (
          <ButtonEmpty
            size="s"
            className="onboard-account-button"
            onClick={() => {
              tracker.track('In-App Alert V2: Create Account Clicked');
              showFlyout(<SubmissionFlyout orgName={enterpriseOrgName} />);
            }}
            iconName="plus"
            label="Create client account"
          />
        )}
      </SubmissionsDashboardProvider>
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
