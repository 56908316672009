import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPanel,
  EuiText,
  EuiTitle,
  FilePicker,
  useToast,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import SOVHealthCircle from '@app/cx/Dashboard/Submissions/SOVHealthCircle';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { useGetSubmissionsPageQuery } from '@app/graphql/queries/submissions/__generated__/getSubmissionsV2.generated';
import { formatDate } from '@app/utils/format';
import Currency from '../Currency/Currency';
import { SOVSummary } from '../SOVSummary/SOVSummary';
import { StyledTaskTable } from './SOVSummaryEnterprise.emotion';

export const SOVSummaryEnterprise = () => {
  const { stream } = useStreamContext();
  const { account } = useAuth();
  const history = useHistory();

  const { selectedOrganization } = useUserSession();
  const organizationName = selectedOrganization?.name;

  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProperties || o.editSubmissions,
  );
  const canManageSubmissions =
    permissions?.includes('canManageSubmissions') || hasNonViewManagedOrgs;

  const { data: orgInfoData } = useOrgInfoQuery({
    skip: !organizationName,
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const isEnterpriseOrgChild = !!orgInfoData?.orgInfo?.orgInfo?.enterpriseOrg;
  const toast = useToast();

  const { loading, data } = useGetSubmissionsPageQuery({
    onError: () =>
      toast({
        title: 'There was an error retrieving your submissions. Please refresh the page.',
        type: 'danger',
      }),
    skip: !organizationName,
    variables: {
      input: {
        filters: [],
        latestOnly: true,
        offset: 0,
        orgName: organizationName,
        pageSize: 10000,
      },
    },
  });

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';

  const formMethods = useForm({ mode: 'onSubmit' });
  const [files, setFiles] = useState([]);

  const updateFile = (file?: File) => {
    if (file) {
      setFiles([file]);
      formMethods.setValue('sovFile', file);
    } else {
      setFiles(undefined);
    }
  };

  const noData = data?.submissionsPageV2?.submissions?.length === 0 || !data;

  const header = (
    <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
      <EuiTitle size="xs">
        <p>Accounts</p>
      </EuiTitle>
    </EuiFlexGroup>
  );

  if (noData && !loading) {
    return (
      <EuiPanel paddingSize="l">
        {header}
        <FormProvider {...formMethods}>
          <FilePicker
            prompt="Upload an SOV"
            data-testid="upload-sov"
            files={files}
            onChange={(items?: File[]) => items && updateFile(items[0])}
          />
        </FormProvider>
      </EuiPanel>
    );
  }

  const columns = [
    {
      field: 'name',
      name: 'Account Name',
      render: (name) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '22%',
    },
    {
      field: 'totalInsuredValue',
      name: `TIV ${stream?.displayCurrency ? `(${stream?.displayCurrency})` : ''}`,
      render: (tiv, streamData) => (
        <EuiText size="s">
          {isProcessing ? null : <Currency value={tiv} currency={streamData.displayCurrency} />}
        </EuiText>
      ),
      sortable: true,
      width: '10%',
    },
    {
      field: 'propertiesCount',
      name: '# properties',
      render: (propertiesCount) =>
        isProcessing ? null : <EuiText size="s">{propertiesCount}</EuiText>,
      sortable: true,
      width: '10%',
    },
    {
      field: 'brokerEmail',
      name: 'Primary Contact',
      render: (brokerEmail) => (
        <EuiFlexGroup>
          {brokerEmail ? <EuiLink href={`mailto:${brokerEmail}`}>{brokerEmail}</EuiLink> : '-'}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '15%',
    },
  ];

  const lastColumns = [
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      render: (effectiveDate) => <EuiText size="s">{formatDate(effectiveDate)}</EuiText>,
      sortable: true,
      width: '10%',
    },
    {
      field: 'expiryDate',
      name: 'Expiry Date',
      render: (expiryDate) => <EuiText size="s">{formatDate(expiryDate)}</EuiText>,
      sortable: true,
      width: '10%',
    },
  ];

  const effectiveColumns = isEnterpriseOrgChild
    ? [...columns, ...lastColumns]
    : [
        ...columns,
        {
          align: 'center',
          field: 'accountHealth',
          name: 'Account Health',
          render: (accountHealth) => (
            <EuiFlexGroup justifyContent="center">
              <SOVHealthCircle healthScore={accountHealth} />
            </EuiFlexGroup>
          ),
          sortable: true,
          width: '12%',
        },
        ...lastColumns,
      ];

  // use a different sort than apps/client/src/components/Portfolios/utils.ts because the
  // response is returned in this format 2025-11-07T00:00:00Z
  const effectiveData = [...(data?.submissionsPageV2?.submissions || [])]
    .sort((a, b) => {
      if (a.expiryDate === null && b.expiryDate !== null) {
        return 1;
      }
      if (a.expiryDate !== null && b.expiryDate === null) {
        return -1;
      }
      if (a.expiryDate === null && b.expiryDate === null) {
        return 0;
      }

      const dateA = new Date(a.expiryDate).getTime();
      const dateB = new Date(b.expiryDate).getTime();

      return dateB - dateA;
    })
    .slice(0, 3);

  return (
    <>
      {canManageSubmissions ? (
        <EuiPanel paddingSize="l">
          {header}
          <StyledTaskTable
            loading={loading}
            items={effectiveData}
            rowProps={(submission: any) => ({
              onClick: () => {
                history.push({
                  pathname: encodeUrl`/streams/${submission?.stream?.slug}`,
                });
              },
            })}
            columns={effectiveColumns}
          />
          <EuiFlexItem data-testid="sov-summary-enterprise-view-all" grow={false}>
            <Button
              onClick={() => {
                if (isEnterpriseOrgChild) {
                  history.push({
                    pathname: encodeUrl`/organizations/${selectedOrganization.name}/streams`,
                  });
                } else {
                  history.push({
                    pathname: encodeUrl`/organizations/${selectedOrganization.name}/accounts`,
                  });
                }
              }}
              size="s"
              label={'View All'}
            />
          </EuiFlexItem>
        </EuiPanel>
      ) : (
        <SOVSummary />
      )}
    </>
  );
};
