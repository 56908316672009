import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SubmissionFragment = { __typename?: 'Submission', id: string, orgName: string, totalInsuredValue?: number | null, propertiesCount?: number | null, name?: string | null, broker?: string | null, effectiveDate?: any | null, adminEmail?: string | null, brokerEmail?: string | null, expiryDate?: any | null, accountHealth?: number | null, stream?: { __typename?: 'Stream', id: string, slug?: string | null } | null };

export const SubmissionFragmentDoc = gql`
    fragment submission on Submission {
  id
  orgName
  totalInsuredValue
  propertiesCount
  name
  broker
  effectiveDate
  adminEmail
  brokerEmail
  expiryDate
  accountHealth
  stream {
    id
    slug
  }
}
    `;