import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import queryString from 'query-string';
import {
  Button,
  ButtonEmpty,
  ButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRowProps,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  EuiToolTip,
  Icon,
  Spacer,
  useEuiTheme,
  useToast,
} from 'ui';
import { FormGoogleAutocompleteInput } from '@app/components/Form/FormGoogleAutocompleteInput';
import { EditFormField } from '@app/components/PropertiesDataGrid/CellPopoverContent/EditCellPopoverContent/EditFormField';
import { ColumnKey } from '@app/components/PropertiesGrid/types';
import { propertiesModalPathname, useDecodedParams } from '@app/containers/App/Routes/utils';
import {
  formatEditError,
  getFinalNewPropertyValues,
} from '@app/cx/PropertyModal/PropertyModalHeader/utils';
import {
  getAttributeRow,
  UNEDITABLE_KEYS,
} from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useIntersection } from '@app/dice/ProvenanceEnrichment/utils/useIntersection';
import { useApplyEditPropertyJobMutation } from '@app/graphql/jobs/mutations/__generated__/applyEditPropertyJob.generated';
import { AttributeLocks, JobStatus } from '@app/graphql/types';
import { useJobPoller } from '@app/hooks/useJobPoller';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { CancelModal } from '../Stream/AddPropertyFlyout/CancelModal/CancelModal';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  ADDRESS_INPUT_KEYS,
  AttributeDataType,
  CUSTOM_ATTRIBUTES_KEY,
  GEO_INPUT_KEYS,
  STREET_ADDRESS_INPUT_KEY,
} from '../Stream/AddPropertyFlyout/constants';
import { hasAddressFields, makeOriginalAddressField } from '../Stream/AddPropertyFlyout/utils';
import { useStreamContext } from '../Stream/StreamProvider';
import ProvenanceTooltip from './ProvenanceTooltip';
import { generateFieldGroups } from './utils';

const StyledFlyout = styled(EuiFlyout)`
  inline-size: 45vw !important;
`;

const StyledEuiFlyoutHeader = styled(EuiFlyoutHeader)`
  padding: 24px !important;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledFlyoutBody = styled(EuiFlyoutBody)`
  height: calc(100% - 177px);
  padding: 24px 0 0 !important;
`;

const StyledSection = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
  padding: 0 24px 24px;

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const StyledEuiFlyoutFooter = styled(EuiFlyoutFooter)`
  padding: 16px 24px !important;
`;

const StyledNav = styled.div`
  width: 250px;
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  position: absolute;
  top: 0;
  left: -250px;
  background-color: white;
  padding: 0 24px 24px;
  transition: all 0.3s ease;

  &.closed {
    left: 0;
    padding: 0;
    width: 0;
    border: none;
  }

  &.closed .nav-item {
    display: none;
  }
`;

const StyledNavItem = styled(EuiTextColor)`
  cursor: pointer;
  padding: 8px;
  border-left: 2px solid transparent;

  &.active,
  &:hover {
    font-weight: 600;
    border-color: ${({ theme }) => theme.euiTheme.colors.primary};
    color: ${({ theme }) => theme.euiTheme.colors.primary};
  }
`;

interface PropertyAttributesEditFlyoutProps {
  property: any;
  columnId: string;
  onClose: () => void;
  attributeLocks: AttributeLocks[];
  refetch: () => void;
}

const skippedFields = ['locationId', 'pictures', 'region', 'ownerAttributes'];

const PropertyAttributesEditFlyout: FC<PropertyAttributesEditFlyoutProps> = ({
  property,
  columnId,
  onClose,
  attributeLocks = [],
  refetch,
}) => {
  const { euiTheme } = useEuiTheme();
  const { stream, propertyAttributeMetadata } = useStreamContext();
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();
  const params = useDecodedParams();
  const {
    location: { search },
  } = window;
  const qs = queryString.parse(search);
  const tracker = useTracker();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [hasSelectedGoogleAddress, setHasSelectedGoogleAddress] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);

  const sections = useMemo(
    () => generateFieldGroups(propertyAttributeMetadata || [], { property }),
    [propertyAttributeMetadata, property],
  );

  const [selectedSection, setSelectedSection] = useState(sections[0]?.label);

  const propertyLock = useMemo(
    () => attributeLocks?.find((lock) => lock?.propertyArchipelagoID === property?.archipelagoId),
    [attributeLocks],
  );

  const customFields = useMemo(
    () => propertyAttributeMetadata.filter((field) => field.isCustom).map((field) => field.name),
    [propertyAttributeMetadata],
  );

  const otherEditableFields = useMemo(
    () =>
      propertyAttributeMetadata
        .filter((field) => !skippedFields.includes(field.name))
        .map((field) => {
          const value = property[field.name] ?? property?.ownerAttributes?.[field.name];
          return {
            ...field,
            value: value,
          };
        }),
    [propertyAttributeMetadata, property],
  );

  const formMethods = useForm({
    defaultValues: otherEditableFields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {}),
    mode: 'all',
  });

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(columnId)?.scrollIntoView();
    }, 100);
    setTimeout(() => {
      const firstInput = document.querySelector(`#${columnId}  input`);
      if (firstInput) {
        (firstInput as HTMLInputElement).focus();
        const section = sections.find((section) => section.data.includes(columnId));
        if (section) {
          setSelectedSection(section.label);
        }
      }
    }, 1000);
  }, [columnId, sections]);

  useEffect(() => {
    const trackerProps = {
      archipelago_id: property?.archipelagoId,
      attribute: columnId,
      event_surface: TrackGroupSovManager.event_surface,
      location_id: property?.locationId,
      location_name: property?.locationName,
      organization_name: stream?.orgName,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    };
    tracker.track(`${TrackGroupSovManager.prefix}: Open edit attributes flyout`, trackerProps);

    return () => {
      tracker.track(`${TrackGroupSovManager.prefix}: Close edit attributes flyout`, trackerProps);
    };
  }, [property]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
  } = formMethods;

  const valuationCurrency = useWatch({
    control: formMethods.control,
    name: 'valuationCurrency' as never,
  }) as string;

  const onUpdateError = (err: ApolloError) =>
    toast({
      title: formatEditError(getErrorMessage(err), formMethods.getValues()),
      type: 'danger',
    });

  const onUpdateCompleted = () => {
    toast({ title: 'Property successfully updated' });
    refetch();
    onClose();
  };

  const onTimeout = () => {
    toast({
      title:
        'Edit property job is taking longer than expected. Please check the page later for your changes.',
      type: 'danger',
    });
  };

  const { startGetJobPoll, isPolling } = useJobPoller(onUpdateCompleted, {
    interval: 2000,
    onError: onUpdateError,
    onTimeout,
    retryCount: 30,
    targetStatus: [JobStatus.Complete],
  });

  const [applyEditPropertyJob, { loading: submitLoading }] = useApplyEditPropertyJobMutation({
    client: jobsApolloClient,
    onError: onUpdateError,
  });

  const onSubmit = async (formData: any) => {
    let changedFields = Object.entries(formData).reduce((acc, [key, value]) => {
      const originalValue = property[key] ?? property?.ownerAttributes?.[key];
      if (value !== originalValue) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const customerProvidedGeocode =
      !hasSelectedGoogleAddress && GEO_INPUT_KEYS.every((key) => formData[key] !== property[key]);

    if (
      hasSelectedGoogleAddress &&
      ADDRESS_AND_GEO_INPUT_KEYS.find((item) => changedFields[item])
    ) {
      for (const key of ADDRESS_AND_GEO_INPUT_KEYS) {
        changedFields = {
          ...changedFields,
          [key]: formData[key],
        };
      }
    }

    if (!hasSelectedGoogleAddress && hasAddressFields(changedFields)) {
      // use originalAddress if the google autocomplete value was not used.
      const originalAddress = makeOriginalAddressField(formData);
      changedFields = {
        ...changedFields,
        originalAddress,
      };
      ADDRESS_INPUT_KEYS.forEach((key) => delete changedFields[key]);
    }

    const changedFieldsKeys = Object.keys(changedFields);

    let attributeNames = changedFieldsKeys.filter(
      (key) => !propertyAttributeMetadata.find(({ name }) => name === key)?.derived,
    );

    if (attributeNames.length === 0) {
      return;
    }

    const hasCustomAttributes = customFields.some((field) => attributeNames.includes(field));
    const newValues = getFinalNewPropertyValues(changedFields, customFields);

    if (hasCustomAttributes) {
      attributeNames = attributeNames.filter((field) => !customFields.includes(field as ColumnKey));
      attributeNames.push(CUSTOM_ATTRIBUTES_KEY);
    }

    try {
      const { data: newEditJob } = await applyEditPropertyJob({
        variables: {
          input: {
            attributeNames: attributeNames,
            customerProvidedGeocode: customerProvidedGeocode,
            orgName: property.orgName,
            propertyArchipelagoID: property.archipelagoId,
            propertyData: newValues,
            streamSlug: stream.slug,
          },
        },
      });

      startGetJobPoll(newEditJob.applyEditPropertyJob.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const attributeProvenance = property?.attributeProvenance || [];
  const editProvenance = property?.attributeEdits || [];

  const renderFieldInput = (field: any) => {
    if (field === undefined) {
      return;
    }

    const itemProvenance = attributeProvenance.find((prov) => prov.attributeName === field.name);
    const editedProvenance = editProvenance.find((prov) => prov.attributeName === field.name);

    const isUneditable = UNEDITABLE_KEYS.has(field?.name);
    const readOnly =
      isUneditable || field.derived || !!propertyLock?.attributeNames?.includes(field.name);

    const { dataType } = field;
    let inputField;
    if (field.name === STREET_ADDRESS_INPUT_KEY) {
      const name = STREET_ADDRESS_INPUT_KEY;
      inputField = (
        <FormGoogleAutocompleteInput
          // FIX ME
          // @ts-ignore
          onChange={(value) => setValue(name, value)}
          name={STREET_ADDRESS_INPUT_KEY}
          formRowProps={{ fullWidth: true } as EuiFormRowProps}
          onSelect={(values) => {
            setHasSelectedGoogleAddress(true);
            Object.keys(values).forEach((item) => {
              // FIX ME
              // @ts-ignore
              setValue(item, values[item]);
            });
          }}
          inputProps={{
            fullWidth: true,
            predictionTypes: ['address'],
          }}
        />
      );
    } else {
      switch (dataType) {
        case 'currency':
        case 'currencyLocal':
          inputField = (
            <EditFormField
              name={field.name}
              currency={valuationCurrency}
              fullWidth={true}
              type={dataType}
              readOnly={readOnly}
            />
          );
          break;
        case 'hazardIcon':
          inputField = getAttributeRow(
            {
              ...field,
              dataType: AttributeDataType.Boolean,
              displayName: null,
              name: field.name,
              value: field.value === '1' || field.value === true,
            },
            propertyLock,
            property,
            {
              labelAction: null,
              readOnly: readOnly,
            },
          );
          break;
        default:
          inputField = getAttributeRow(
            {
              ...field,
              displayName: null,
              name: field.name,
              value:
                dataType === AttributeDataType.Boolean
                  ? field.value === '1' || field.value === true
                  : field.value,
            },
            propertyLock,
            property,
            {
              fullWidth: field.dataType === AttributeDataType.Year,
              labelAction: null,
              readOnly: readOnly,
            },
          );
          break;
      }
    }

    let tooltipContent = 'Another user is already editing attribute';

    if (isUneditable) {
      tooltipContent = 'This attribute cannot be edited';
    } else if (field.derived) {
      tooltipContent = 'This attribute is derived and cannot be edited';
    }

    const showNormalDisplayName = !editedProvenance && !itemProvenance;

    return (
      <>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          {itemProvenance && (
            <ProvenanceTooltip isFullWidth={false} provenance={itemProvenance}>
              <EuiText
                style={{
                  borderBottom: `2px solid ${euiTheme.colors.mediumShade}`,
                  cursor: 'pointer',
                }}
              >
                {field.displayName}
              </EuiText>
            </ProvenanceTooltip>
          )}
          {editedProvenance && (
            <EuiToolTip content={`Edited by ${editedProvenance.author}`}>
              <EuiText
                style={{
                  borderBottom: `2px solid ${euiTheme.colors.mediumShade}`,
                  cursor: 'pointer',
                }}
              >
                {field.displayName}
              </EuiText>
            </EuiToolTip>
          )}
          {showNormalDisplayName && <EuiText>{field.displayName}</EuiText>}
          {readOnly && (
            <EuiToolTip title="Cannot Edit Attribute" content={tooltipContent}>
              <Icon color="primary" name="info" />
            </EuiToolTip>
          )}
        </EuiFlexGroup>
        <EuiSpacer size="xs" />
        {inputField}
      </>
    );
  };

  const closeFlyout = () => {
    if (isDirty) {
      setIsCancelModalOpen(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <StyledFlyout paddingSize="none" onClose={closeFlyout} aria-labelledby="propertyFlyoutTitle">
        <StyledNav className={isNavOpen ? 'open' : 'closed'}>
          <EuiFlexGroup alignItems="center" justifyContent="flexEnd" style={{ height: '73px' }}>
            {isNavOpen && (
              <EuiFlexItem grow={false}>
                <ButtonEmpty
                  label="Collapse"
                  size="m"
                  iconSide="right"
                  iconName="chevronRight"
                  onClick={() => setIsNavOpen(!isNavOpen)}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiFlexGroup direction="column" gutterSize="l">
            {sections.map((section) => (
              <EuiFlexItem key={`button-${section.label}`}>
                <StyledNavItem
                  data-testid={`attribute-section-button-${section.label}`}
                  className={`nav-item ${section.label === selectedSection ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedSection(section.label);
                    document
                      .querySelector(`#${section.label.replace(/ /g, '-')}-flyout-section h3`)
                      ?.scrollIntoView({
                        behavior: 'smooth',
                      });
                  }}
                >
                  <h3>{section.label}</h3>
                </StyledNavItem>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </StyledNav>
        <FormProvider {...formMethods}>
          <StyledEuiFlyoutHeader hasBorder>
            <EuiFlexGroup alignItems="flexStart" gutterSize="m">
              {!isNavOpen && (
                <EuiFlexItem grow={false}>
                  <ButtonIconMemo onClick={() => setIsNavOpen(!isNavOpen)} />
                </EuiFlexItem>
              )}
              <EuiFlexItem>
                <EuiTitle size="s">
                  <EuiFlexItem>
                    <EuiText style={{ fontSize: '1.375rem', fontWeight: 600 }}>
                      Editing&nbsp;
                      <EuiTextColor color={euiTheme.colors.primary}>
                        <Link
                          style={{ fontWeight: 600 }}
                          to={{
                            pathname: propertiesModalPathname(stream, params, property.id),
                            search: queryString.stringify({ ...qs }),
                          }}
                        >
                          {property.streetAddress || property?.locationName}
                        </Link>
                      </EuiTextColor>
                    </EuiText>
                  </EuiFlexItem>
                </EuiTitle>
                <Spacer size="s" />
                <EuiText>
                  Archipelago ID:&nbsp;
                  <Link
                    to={{
                      pathname: propertiesModalPathname(stream, params, property.id),
                      search: queryString.stringify({ ...qs }),
                    }}
                  >
                    {property.archipelagoId}
                  </Link>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </StyledEuiFlyoutHeader>
          <StyledForm id="edit-property-form" onSubmit={handleSubmit(onSubmit)}>
            <StyledFlyoutBody>
              {sections.map((section) => (
                <Section
                  key={section.label}
                  section={section}
                  renderFieldInput={renderFieldInput}
                  fields={otherEditableFields.filter((field) => section.data.includes(field.name))}
                  setSelectedSection={setSelectedSection}
                />
              ))}
            </StyledFlyoutBody>
            <StyledEuiFlyoutFooter>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <ButtonEmpty
                      loading={submitLoading}
                      disabled={submitLoading || isPolling}
                      onClick={closeFlyout}
                      label="Cancel"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <Button
                      loading={submitLoading || isPolling}
                      disabled={submitLoading || isPolling || !isValid || !isDirty}
                      fill
                      type="submit"
                      label="Save"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexGroup>
            </StyledEuiFlyoutFooter>
          </StyledForm>
        </FormProvider>
      </StyledFlyout>
      {isCancelModalOpen && (
        <CancelModal
          isEditMode
          onCancel={() => setIsCancelModalOpen(false)}
          onConfirm={() => {
            onClose();
          }}
        />
      )}
    </>
  );
};

interface SectionProps {
  section: { label: string; data: string[] };
  renderFieldInput: (field: string) => React.ReactNode;
  fields: any[];
  setSelectedSection: (section: string) => void;
}

const Section: FC<SectionProps> = ({ section, renderFieldInput, fields, setSelectedSection }) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, [0.3, 0.9], 0.3);

  useEffect(() => {
    if (inViewport) {
      setSelectedSection(section.label);
    }
  }, [inViewport]);

  return (
    <StyledSection ref={ref} id={`${section.label.replace(/ /g, '-')}-flyout-section`}>
      <EuiText>
        <h3>{section.label}</h3>
      </EuiText>
      <Spacer size="m" />
      <EuiFlexGroup direction="column" gutterSize="m">
        {fields.map((field) => (
          <div id={field.name} data-testId={`attribute-${field.name}`} key={field.name}>
            {renderFieldInput(field)}
          </div>
        ))}
      </EuiFlexGroup>
    </StyledSection>
  );
};

export default PropertyAttributesEditFlyout;

const ButtonIconMemo = memo(({ onClick }: { onClick: () => void }) => (
  <ButtonIcon color="primary" iconName="chevronLeft" size={'m'} onClick={onClick} />
));
