import { generatePath } from 'react-router';
import { Icon } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { NavItem } from './NavItem';

export const HomeLink = () => {
  const { account } = useAuth();
  const { selectedOrganization } = useUserSession();

  const managedOrgs = account?.managedOrgs?.length;
  const isAdmin = account?.permissions?.admin;
  const canViewHome = managedOrgs > 0 || isAdmin;

  const homeLink = selectedOrganization?.name
    ? generatePath('/organizations/:organizationName/home', {
        organizationName: selectedOrganization?.name || '',
      })
    : '/';

  return (
    canViewHome && (
      <NavItem testId="header-home-tab" icon={<Icon name="home" />} link={homeLink} label="Home" />
    )
  );
};

export const Home = () => <HomeLink />;
