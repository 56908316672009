import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const handlePDFExport = async (input, pdfName) => {
  input.scrollTop = 0;

  const linkContainer = input.querySelector('[data-hide-print="true"]');
  if (linkContainer) {
    linkContainer.classList.add('hide-for-print');
  }

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  const canvas = await html2canvas(input, {
    logging: true,
    scale: 1,
    useCORS: true,
    windowHeight: input.scrollHeight,
    windowWidth: input.scrollWidth,
  });
  const imgHeight = (canvas.height * input.scrollWidth) / canvas.width;

  let heightLeft = imgHeight;
  const pageCanvas = document.createElement('canvas');
  const ctx = pageCanvas.getContext('2d');
  const pageHeightPx = (canvas.width * pageHeight) / pageWidth;

  while (heightLeft > 0) {
    const sourceY = canvas.height - heightLeft;
    const chunkHeight = Math.min(pageHeightPx, heightLeft);
    pageCanvas.width = canvas.width;
    pageCanvas.height = chunkHeight;
    ctx.clearRect(0, 0, canvas.width, chunkHeight);
    ctx.drawImage(canvas, 0, sourceY, canvas.width, chunkHeight, 0, 0, canvas.width, chunkHeight);
    const imgData = pageCanvas.toDataURL('image/png', 0.8);
    pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, (chunkHeight * pageWidth) / canvas.width);
    heightLeft -= chunkHeight;
    if (heightLeft > 0) {
      pdf.addPage();
    }
  }

  pdf.save(`${pdfName}.pdf`);

  if (linkContainer) {
    linkContainer.classList.remove('hide-for-print');
  }
};
