import { useEffect, useState } from 'react';

const useIntersection = (
  element: any,
  threshold?: number[] | undefined,
  ratio?: number | undefined,
) => {
  const [isVisible, setState] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (!ratio) {
        setState(entry.isIntersecting);
        return;
      }

      if (entry.isIntersecting && entry.intersectionRatio > ratio) {
        setState(true);
      } else {
        setState(false);
      }
    },
    {
      threshold: threshold,
    },
  );

  useEffect(() => {
    observer.observe(element.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isVisible;
};

export { useIntersection };
