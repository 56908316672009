import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { OrgLevelType } from '@app/graphql/types';
import useSessionStorage from '@app/hooks/useSessionStorage';
import { FEATURE_IN_APP_ALERT_V1 } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';

const MESSAGES = {
  V1: 'Update: TIV and SOVs are now uncapped.',
};

const CURRENT_VERSION = 'V1';

interface AlertProps {
  hideAlert: () => void;
  message: string;
  shouldShowAlert: boolean;
  enterpriseOrgName?: string;
}

export const useAlert = (): AlertProps => {
  try {
    const { account } = useAuth();
    const { selectedOrganization } = useUserSession();

    const featureEnabled = isFeatureEnabled(
      selectedOrganization?.enabledFeatures,
      FEATURE_IN_APP_ALERT_V1,
    );

    const permissions = getOrgPermissions(account, selectedOrganization?.id);
    const isReviewer = !account?.managedOrgs?.some((o) => o.editProperties || o.editSubmissions);
    const canManageSubmissions =
      permissions?.includes('canManageSubmissions') || (account.organizationName && !isReviewer);

    if (!featureEnabled || !canManageSubmissions) {
      return {
        enterpriseOrgName: null,
        hideAlert: () => {},
        message: '',
        shouldShowAlert: false,
      };
    }

    const { data } = useOrgInfoQuery({
      skip: !featureEnabled,
      variables: {
        input: {
          orgName: selectedOrganization?.name,
        },
      },
    });
    const alertLSKey = `ALERT-${CURRENT_VERSION}`;

    const [hasDismissedAlert, dismissAlert] = useSessionStorage(account, alertLSKey, 'false');

    const hasCreateOrganizationPermission = data?.orgInfo?.permissionsV2?.some(
      (permission) => permission.resource === 'Organization' && permission.action === 'Create',
    );

    const isEnterprise = selectedOrganization?.orgLevel === OrgLevelType.Enterprise;

    const enterpriseOrgName = isEnterprise
      ? selectedOrganization.name
      : data?.orgInfo?.orgInfo?.enterpriseOrg?.name;

    const hideAlert = () => {
      dismissAlert('true');
    };

    const shouldShowAlert =
      hasCreateOrganizationPermission && enterpriseOrgName && hasDismissedAlert !== 'true';

    return {
      enterpriseOrgName,
      hideAlert,
      message: MESSAGES[CURRENT_VERSION],
      shouldShowAlert,
    } as AlertProps;
  } catch (e) {
    console.error('error showing alert', e);

    return {
      enterpriseOrgName: null,
      hideAlert: () => {},
      message: '',
      shouldShowAlert: false,
    };
  }
};
