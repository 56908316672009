import { IGraphQLProperty } from '@app/queries/properties/generated_types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { formatCurrency, formatNumber, formatPercentage } from '@app/utils/format';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generateGroups = (propertyMetaData: IGraphQLAttributeMetadata[], property) => {
  const groups = [];

  const skippedFields = ['locationId', 'pictures'];

  propertyMetaData.forEach((d) => {
    if (skippedFields.includes(d.name)) {
      return;
    }
    const dimensionGroup = groups.find((g) => g.label === d.dimension);
    if (!dimensionGroup) {
      if (d.dimension) {
        groups.push({ data: [d.name], label: d.dimension });
      }
    }
  });

  propertyMetaData.forEach((d) => {
    const dimensionGroup = groups.find((g) => g.label === d.dimension);
    if (dimensionGroup && !dimensionGroup.data.includes(d.name)) {
      dimensionGroup.data.push(d.name);
    }
  });

  return groups;
};

export const generateFieldGroups = (propertyMetaData: IGraphQLAttributeMetadata[], property) => {
  const attributesMetaData = (propertyMetaData || []).map((item) => {
    if (item.isCustom && !item.dimension && !item.hiddenInGrid && item.name !== 'ownerAttributes') {
      return {
        ...item,
        dimension: 'Owner Attributes',
      };
    }

    if (!item.isCustom && !item.dimension && !item.hiddenInGrid && !item.parent) {
      return {
        ...item,
        dimension: 'Additional Attributes',
      };
    }
    return item;
  });

  const groups = generateGroups(attributesMetaData, property);

  const skippedFields = ['locationId', 'pictures'];

  attributesMetaData.forEach((d) => {
    if (skippedFields.includes(d.name)) {
      return;
    }

    const parentGroup = groups.find((g) => g.data.includes(d.parent));
    if (parentGroup && !parentGroup.data.includes(d.name)) {
      parentGroup.data.push(d.name);
    }
  });

  return groups.sort((a, b) => {
    const priority = { 'Additional Attributes': 1, 'Owner Attributes': 1 };
    return (priority[a.label] || 0) - (priority[b.label] || 0);
  });
};

export const shouldGroupBeGeneratedInChangeAnalysis = (
  beforeValues: IGraphQLProperty,
  afterValues: IGraphQLProperty,
  fields: any[],
  propertyMetaData: any,
) => {
  let hasDiff = false;

  const pairedFields = fields.map((f) => {
    const fieldType = propertyMetaData.find((m) => m.name === f.name).dataType;
    return {
      after: formatComparisonValue(afterValues[f.name], fieldType),
      before: formatComparisonValue(beforeValues[f.name], fieldType),
    };
  });

  pairedFields.forEach((f) => {
    if (f.after !== f.before) {
      hasDiff = true;
    }
  });
  return !hasDiff;
};

export const countChanges = (
  beforeValues: IGraphQLProperty,
  afterValues: IGraphQLProperty,
  fields: any[],
) => {
  let changeCount = 0;
  const pairedFields = fields.map((f) => ({
    after: afterValues[f.name],
    before: beforeValues[f.name],
  }));

  pairedFields.forEach((f) => {
    if (f.after !== f.before) {
      changeCount += 1;
    }
  });
  return changeCount;
};

export const countAdditions = (
  beforeValues: IGraphQLProperty,
  afterValues: IGraphQLProperty,
  fields: any[],
) => {
  let changeCount = 0;
  const pairedFields = fields.map((f) => ({
    after: afterValues[f.name],
    before: beforeValues[f.name],
  }));

  pairedFields.forEach((f) => {
    if (f.after !== f.before && (f.before === null || f.before === undefined)) {
      changeCount += 1;
    }
  });
  return changeCount;
};

export const countEdits = (
  beforeValues: IGraphQLProperty,
  afterValues: IGraphQLProperty,
  fields: any[],
) => {
  let changeCount = 0;
  const pairedFields = fields.map((f) => ({
    after: afterValues[f.name],
    before: beforeValues[f.name],
  }));

  pairedFields.forEach((f) => {
    if (f.after !== f.before) {
      changeCount += 1;
    }
  });
  return changeCount;
};

export const generateChangeTypeSections = (
  beforeValues: IGraphQLProperty,
  afterValues: IGraphQLProperty,
  propertyMetaData: IGraphQLAttributeMetadata[],
) => {
  const sections = {
    changedValues: [],
    newValues: [],
  };
  propertyMetaData?.forEach((f) => {
    if (!f.name.includes('owner') && f.name !== 'pictures') {
      if (beforeValues[f.name] !== afterValues[f.name]) {
        if (!beforeValues[f.name]) {
          sections.newValues.push({
            after: afterValues[f.name],
            before: beforeValues[f.name],
            fieldName: f.displayName,
            fieldType: f.dataType,
            name: f.name,
          });
        } else {
          sections.changedValues.push({
            after: afterValues[f.name],
            before: beforeValues[f.name],
            fieldName: f.displayName,
            fieldType: f.dataType,
            name: f.name,
          });
        }
      }
    }
  });

  return sections;
};

export const formatComparisonValue = (value?: string, dataType?: string) => {
  switch (dataType) {
    case 'currencyLocal':
      return formatCurrency(value);
    case 'currency':
      return formatCurrency(value);
    case 'percentage':
      return formatPercentage(value);
    case 'number':
      return formatNumber(value);
    default:
      return value || '';
  }
};
