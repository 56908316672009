import { generatePath } from 'react-router';
import { EuiIcon, Icon } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { OrgLevelType } from '@app/graphql/types';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

export const BulbIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.05289 13.1667H10.947M8.99995 1.5V2.33333M14.3033 3.6967L13.714 4.28596M16.5 8.99996H15.6667M2.33333 8.99996H1.5M4.28591 4.28595L3.69665 3.6967M6.05368 11.9463C4.42649 10.3191 4.42649 7.68093 6.05368 6.05374C7.68086 4.42656 10.319 4.42656 11.9462 6.05374C13.5734 7.68093 13.5734 10.3191 11.9462 11.9463L11.4903 12.4022C10.9629 12.9296 10.6666 13.6449 10.6666 14.3908V14.8333C10.6666 15.7538 9.92043 16.5 8.99995 16.5C8.07948 16.5 7.33329 15.7538 7.33329 14.8333V14.3908C7.33329 13.6449 7.037 12.9296 6.50959 12.4022L6.05368 11.9463Z"
      stroke="#343741"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Hub = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();

  // current check for users who can see hub links are users who are on
  // an enterprise org, or have their primary org set (pimrary org is the
  // organizationName that is set on account, having that set should mean
  // the user has an enterprise org)
  const isAdmin = account?.permissions?.admin;
  const isReviewer =
    !account?.managedOrgs?.some((o) => o.editProperties || o.editSubmissions) && !isAdmin;
  const canViewHub =
    (selectedOrganization?.orgLevel === OrgLevelType.Enterprise || account?.organizationName) &&
    !isReviewer;

  if (!canViewHub || !selectedOrganization) {
    return null;
  }

  // only link to enterprise org for all hub links
  const enterpriseOrgName =
    selectedOrganization?.orgLevel === OrgLevelType.Enterprise
      ? selectedOrganization?.name
      : account.organizationName;

  const spotlightLink = generatePath('/organizations/:organizationName/spotlight', {
    organizationName: enterpriseOrgName,
  });

  const accountLink = generatePath('/organizations/:organizationName/accounts', {
    organizationName: enterpriseOrgName,
  });

  return (
    <NavGroup title="Hub">
      <NavItem icon={<EuiIcon type={BulbIcon} />} link={spotlightLink} label="Spotlight" />
      <NavItem
        testId="header-accounts-tab"
        icon={<Icon name="send" />}
        link={accountLink}
        label="Client Accounts"
      />
    </NavGroup>
  );
};
