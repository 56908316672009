import { useParams } from 'react-router-dom';
import { IGraphQLStream, ManagedOrgInfo } from '@app/queries/streams/types';

export const encodeUrl = (strings: TemplateStringsArray, ...values: (string | undefined)[]) =>
  strings
    .map((string: string, i: number) => {
      const value = values[i] === undefined ? '' : encodeURIComponent(values[i] || '');
      return string + value;
    })
    .join('');

export const propertiesModalPathname = (
  stream?: IGraphQLStream,
  params?: { organizationName?: string; reportType?: string },
  propertyId?: string,
  isInReport = false,
) => {
  if (isInReport) {
    let pathName = `/reports/${stream?.slug}`;
    if (params?.reportType) {
      pathName += `/${params?.reportType}`;
    }
    return (pathName += `/properties/${propertyId}`);
  }
  if (stream?.isMyProperties) {
    const orgName = params?.organizationName;
    if (orgName) {
      // if the org name has a /, we need to replace it with %2F so that the url is valid
      const urlOrgName = orgName.replace(' / ', '%20%2F%20');
      return `/organizations/${urlOrgName}/properties/${propertyId || ''}`;
    }
  }
  return `/streams/${stream?.slug}/properties/${propertyId}`;
};

export const decodeParams = (params?: object) =>
  Object.entries(params || {})?.reduce((acc: object, [key, value]: [string, string]) => {
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

export const useDecodedParams = <T extends object>() => {
  const params = useParams();
  return decodeParams(params) as T;
};

const isStreamPage = (urlPath: string | string[]) =>
  urlPath.length !== 8 && urlPath.includes('/stream');

// Note: This will eventually be refactored with additional logic for multi-org support
export const shouldRedirectToUpload = (
  orgs: ManagedOrgInfo[],
  urlPath: string | string[],
  admin: boolean = false,
) =>
  !admin &&
  orgs.length === 1 &&
  orgs[0].org?.enableUploadPortal &&
  urlPath !== '/upload' &&
  !isStreamPage(urlPath);

export const shouldRedirectToSupport = (
  orgs: ManagedOrgInfo[],
  urlPath: string | string[],
  admin: boolean = false,
) => !admin && orgs.length === 0 && urlPath !== '/support';

export const getRedirectPathName = (
  willRedirectToUploadOrSupport?: boolean,
  selectedOrgName?: string,
) => {
  const orgs: ManagedOrgInfo[] = [];

  if (willRedirectToUploadOrSupport && orgs[0]?.org?.enableUploadPortal) {
    return '/upload';
  } else if (willRedirectToUploadOrSupport && orgs.length === 0) {
    return '/support';
  }

  return `/organizations/${encodeURIComponent(selectedOrgName)}/home`;
};
