import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SubmissionFragmentDoc } from './submissionsFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubmissionsPageQueryVariables = Types.Exact<{
  input: Types.SubmissionsPageInput;
}>;


export type GetSubmissionsPageQuery = { __typename?: 'Query', submissionsPageV2?: { __typename?: 'SubmissionsPage', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, cursor: string, totalCount: number }, submissions?: Array<{ __typename?: 'Submission', id: string, orgName: string, totalInsuredValue?: number | null, propertiesCount?: number | null, name?: string | null, broker?: string | null, effectiveDate?: any | null, adminEmail?: string | null, brokerEmail?: string | null, expiryDate?: any | null, accountHealth?: number | null, stream?: { __typename?: 'Stream', id: string, slug?: string | null } | null }> | null } | null };


export const GetSubmissionsPageDocument = gql`
    query GetSubmissionsPage($input: SubmissionsPageInput!) {
  submissionsPageV2(input: $input) {
    pageInfo {
      hasNextPage
      cursor
      totalCount
    }
    submissions {
      ...submission
    }
  }
}
    ${SubmissionFragmentDoc}`;

/**
 * __useGetSubmissionsPageQuery__
 *
 * To run a query within a React component, call `useGetSubmissionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubmissionsPageQuery(baseOptions: Apollo.QueryHookOptions<GetSubmissionsPageQuery, GetSubmissionsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionsPageQuery, GetSubmissionsPageQueryVariables>(GetSubmissionsPageDocument, options);
      }
export function useGetSubmissionsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionsPageQuery, GetSubmissionsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionsPageQuery, GetSubmissionsPageQueryVariables>(GetSubmissionsPageDocument, options);
        }
export type GetSubmissionsPageQueryHookResult = ReturnType<typeof useGetSubmissionsPageQuery>;
export type GetSubmissionsPageLazyQueryHookResult = ReturnType<typeof useGetSubmissionsPageLazyQuery>;
export type GetSubmissionsPageQueryResult = Apollo.QueryResult<GetSubmissionsPageQuery, GetSubmissionsPageQueryVariables>;